import QuizQuestion, { QuizQuestionForm } from '../../../../components/quiz/QuizQuestion';
import { useWizard } from 'react-use-wizard';
import { useConceptFormStore } from '../../../../utils/stores';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import { fetchAPI, UpdatedResponse } from '../../../../utils/httpRequests';
import Loading from '../../../../components/Loading';
import { useTranslation } from 'react-i18next';

interface ConceptWizardQuestionsProps {
  closeModal: () => void; // Closing function
}

interface QuestionSet {
  id: number;
  editMode: boolean;
  differentiation: boolean;
  question: QuizQuestionForm | null;
}

const ConceptWizardQuestions = ({ closeModal }: ConceptWizardQuestionsProps) => {
  const { t } = useTranslation('global');
  const { previousStep } = useWizard(); // Contains step 2 of the modal
  const { resetStore, part1 } = useConceptFormStore();
  const { id } = useConceptFormStore();
  const [questions, setQuestions] = useState<QuestionSet[]>([
    {
      id: 0,
      editMode: true,
      question: null,
      differentiation: false,
    },
  ]);

  // Get the questions that were previously made
  const { data: originalQuestions, isLoading: isLoading } = useQuery(
    `questions-${id}`,
    async () => {
      if (!id) {
        return null;
      }

      const concepts = await fetchAPI<QuizQuestionForm[]>(`/concepts/${id}/questions`);
      if (concepts.isSuccess) {
        return concepts.data;
      } else {
        return null;
      }
    },
  );

  useEffect(() => {
    // Set the current questions to the original questions
    if (originalQuestions) {
      setQuestions(
        originalQuestions.map((q, i) => ({
          id: i,
          editMode: false,
          question: q,
          differentiation: q.differentiation ?? false,
        })),
      );
    }
  }, [originalQuestions]);

  // Resetting the saved values and closing the modal
  const onClose = () => {
    resetStore();
    closeModal();
  };

  // Save the current questions
  const onSubmit = async () => {
    const response = await fetchAPI<UpdatedResponse>(`/concepts/${id}/questions`, {
      method: 'POST',
      body: {
        conceptQuestions: questions.map((q) => q.question),
      },
    });

    if (response.isSuccess && response.data.updated) {
      onClose();
    }
  };

  const addQuestion = (differentiation: boolean) => {
    setQuestions([
      ...questions,
      {
        id: questions.length + 1,
        editMode: true,
        question: null,
        differentiation,
      },
    ]);
  };

  const setQuestion = (question: QuizQuestionForm, id: number) => {
    setQuestions(
      questions.map((q) => {
        if (q.id === id) {
          return {
            ...q,
            question,
            editMode: false,
          };
        }
        return q;
      }),
    );
  };

  const setActiveModeForQuestion = (id: number) => {
    setQuestions(
      questions.map((q) => {
        if (q.id === id) {
          return {
            ...q,
            editMode: true,
          };
        }
        return q;
      }),
    );
  };

  const deleteQuestion = (id: number) => {
    setQuestions(questions.filter((q) => q.id !== id));
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className='my-2' data-testid='conceptWizardQuestions'>
      <div className='mb-4'>
        <h4>{t('question_without_differentiation')}</h4>
        {questions
          .filter((q) => !q.differentiation)
          .map((question) => (
            <div key={question.id} className='mb-2'>
              <QuizQuestion
                defaultValues={question.question}
                differentiation={question.differentiation}
                requiresFiles={part1?.fileSubmissionType === 'FOR_SOME_QUESTIONS'}
                editModeActive={() => setActiveModeForQuestion(question.id)}
                onSubmit={(f) => setQuestion(f, question.id)}
                deleteQuestion={() => deleteQuestion(question.id)}
              />
            </div>
          ))}
        <button type='button' onClick={() => addQuestion(false)} className='btn btn-sm btn-primary'>
          {t('addANewQuestion')}
        </button>
      </div>
      <div className='mb-4'>
        <h4>{t('question_with_differentiation')}</h4>
        {questions
          .filter((q) => q.differentiation)
          .map((question) => (
            <div key={question.id} className='mb-2'>
              <QuizQuestion
                defaultValues={question.question}
                differentiation={question.differentiation}
                requiresFiles={part1?.fileSubmissionType === 'FOR_SOME_QUESTIONS'}
                editModeActive={() => setActiveModeForQuestion(question.id)}
                onSubmit={(f) => setQuestion(f, question.id)}
                deleteQuestion={() => deleteQuestion(question.id)}
              />
            </div>
          ))}
        <button type='button' onClick={() => addQuestion(true)} className='btn btn-sm btn-primary'>
          {t('addANewQuestion')}
        </button>
      </div>
      <div className='d-flex gap-2 mt-2 pb-2'>
        <button type='button' className='btn btn-secondary' onClick={previousStep}>
          <FontAwesomeIcon icon={faArrowLeft} className='me-1' /> {t('previousStep')}
        </button>
        <button
          type='button'
          disabled={questions.filter((q) => q.editMode || !q.question).length > 0}
          onClick={onSubmit}
          className='btn btn-primary'
        >
          {t('save')}
        </button>
      </div>
    </div>
  );
};

export default ConceptWizardQuestions;
