import { Link, useParams } from 'react-router-dom';
import { fetchCourse } from '../../../utils/httpRequests';
import { useQuery } from 'react-query';
import Loading from '../../../components/Loading';
import { useTranslation } from 'react-i18next';

const QuestSubmissions = () => {
  const { t } = useTranslation('global');
  const { courseId } = useParams<{ courseId: string; questId: string }>();
  const { data: course, isLoading } = useQuery(['course', courseId], () => fetchCourse(courseId));

  return (
    <div className='m-2'>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <h1>{t('questSubmissions')}</h1>
          <h2 className='fs-5'>{t('chooseYourStudent')}</h2>
          <div className='list-group'>
            {course?.participants.map((participant) => (
              <Link
                key={participant.id}
                to={`${participant.id}`}
                className='list-group-item list-group-item-action'
              >
                {participant.user.firstName} {participant.user.lastName}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestSubmissions;
