import Modal from 'react-modal';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface ImageAndPdfModalProps {
  file: string | null;
  isOpen: boolean;
  closeModal: () => void;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const ImageAndPdfModal = ({ file, isOpen, closeModal }: ImageAndPdfModalProps) => {
  const { t } = useTranslation('global');
  const isPdf = file?.includes('.pdf');
  const isImage =
    file?.includes('.png') ||
    file?.includes('.jpg') ||
    file?.includes('.jpeg') ||
    file?.includes('.gif') ||
    file?.includes('.svg') ||
    file?.includes('.webp');

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel='Image / PDF Renderer Modal'>
      <div className='h-full w-full'>
        <div className='d-flex w-100 justify-content-between'>
          <div>
            <h2>{t('filePreview')}</h2>
          </div>
          <button onClick={closeModal} className='btn btn-primary'>
            {t('close')}
          </button>
        </div>
        <div className='h-full w-full d-flex justify-content-center pb-3'>
          {file &&
            (isPdf ? (
              <div>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
                <div className='text-center pb-3'>
                  {pageNumber > 1 && (
                    <button
                      className='btn btn-primary btn-sm me-2'
                      onClick={() => setPageNumber(pageNumber - 1)}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                  )}
                  <span>
                    {t('page')} {pageNumber} {t('of')} {numPages}
                  </span>
                  {numPages && pageNumber < numPages && (
                    <button
                      className='ms-2 btn btn-primary btn-sm'
                      onClick={() => setPageNumber(pageNumber + 1)}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  )}
                </div>
              </div>
            ) : isImage ? (
              <img src={file} alt='Preview' />
            ) : (
              <div>
                <p>{t('fileTypeNotSupported')}</p>
                <a href={file} target='_blank' rel='noreferrer'>
                  {t('openFile')}
                </a>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default ImageAndPdfModal;
