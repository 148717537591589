import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { CreatedResponse, fetchAPI } from '../../utils/httpRequests';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

interface CreateClassModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface CreateCourseBody {
  name: string;
  xpPerLevel: number;
}

const CreateClassModal = ({ isOpen, onClose }: CreateClassModalProps) => {
  const { t } = useTranslation('global');
  const [successMessage, setSuccessMessage] = useState(''); // Contains the success or error message
  const [accessCode, setAccessCode] = useState(''); // Contains the created course's access code
  const queryClient = useQueryClient();
  const minXpPerLevel = 100;
  const maxXpPerLevel = 1000;
  const xpPerLevelSteps = 50;
  const defaultXpPerLevel = 500;
  const [xpPerLevel, setXpPerLevel] = useState(defaultXpPerLevel);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateCourseBody>();

  // Reset values of the modal, refetch queries and close the modal
  const closeModal = async (): Promise<void> => {
    reset();
    setAccessCode('');
    setSuccessMessage('');
    setXpPerLevel(defaultXpPerLevel);
    await queryClient.invalidateQueries('participants');
    await queryClient.invalidateQueries('courses');
    onClose();
  };

  const createCourse = async (body: CreateCourseBody): Promise<void> => {
    const response = await fetchAPI<CreatedResponse>('/courses', {
      method: 'POST',
      body,
    });

    if (response.isSuccess) {
      // Set the accessCode and success message to display
      const courseAccessCode = await getCourseCode(response.data.created);
      setAccessCode(courseAccessCode);
      setSuccessMessage(t('createCourseSuccess'));
    } else {
      // Set the error message to display
      setSuccessMessage(t('createCourseError'));
    }
  };

  const getCourseCode = async (id: number): Promise<string> => {
    const response = await fetchAPI<{ accessCode: string }>('/courses/' + id);

    if (response.isSuccess) {
      return response.data.accessCode;
    }

    return '';
  };

  const handleXpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setXpPerLevel(Number(event.target.value));
  };

  return (
    <>
      {isOpen && (
        <div className='modal-backdrop show' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}></div>
      )}
      <div
        className={`modal ${isOpen ? 'show' : ''}`}
        tabIndex={-1}
        role='dialog'
        style={{ display: isOpen ? 'block' : 'none' }}
        data-testid='createClassModal'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{t('createCourse')}</h5>
              <button
                type='button'
                className='btn btn-close'
                onClick={closeModal}
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              {successMessage ? (
                <div>
                  <div
                    className={
                      successMessage === t('createCourseSuccess')
                        ? 'alert alert-success'
                        : 'alert alert-danger'
                    }
                    role='alert'
                  >
                    {successMessage} {accessCode}
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='button' className='btn btn-secondary' onClick={closeModal}>
                      {t('close')}
                    </button>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit(createCourse)}>
                  <div>
                    <label htmlFor='name' className='form-label'>
                      {t('courseName')}
                    </label>
                    <input
                      type='text'
                      {...register('name', { required: t('courseNameRequiredError') })}
                      className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                    />
                    {errors.name && <div className='invalid-feedback'>{errors.name.message}</div>}
                  </div>
                  <div className='mt-3'>
                    <label htmlFor='xpPerLevel' className='form-label'>
                      {t('xpPerLevel')}
                    </label>
                    <div className='d-flex align-items-center'>
                      <input
                        type='range'
                        {...register('xpPerLevel')}
                        className='form-range'
                        min={minXpPerLevel}
                        max={maxXpPerLevel}
                        step={xpPerLevelSteps}
                        defaultValue={defaultXpPerLevel}
                        onChange={handleXpChange}
                      />
                      <span
                        className='input-group-text ms-2 d-flex justify-content-center align-items-center'
                        style={{ width: 65 }}
                      >
                        {xpPerLevel} XP
                      </span>
                    </div>
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='submit' className='btn btn-primary'>
                      {t('createCourse')}
                    </button>
                    <button type='button' className='btn btn-secondary' onClick={closeModal}>
                      {t('close')}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateClassModal;
