import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fetchAPI } from '../../utils/httpRequests';
import { useState } from 'react';
import Loading from '../Loading';

interface RequestResetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface RequestResetPasswordBody {
  emailOrUsername: string;
  lang: string;
}

const RequestResetPasswordModal = ({ isOpen, onClose }: RequestResetPasswordModalProps) => {
  const { i18n, t } = useTranslation('global');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const closeModal = async (): Promise<void> => {
    reset();
    setSent(false);
    setError(false);
    setSent(false);
    onClose();
  };

  const currentLanguage = i18n.language;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RequestResetPasswordBody>({
    defaultValues: {
      lang: currentLanguage === 'auto' ? 'fr' : '', // Language for email to be sent
    },
  });

  // Function to send email to reset password
  const requestReset = async (body: RequestResetPasswordBody): Promise<void> => {
    setLoading(true);
    setSent(true);
    const minDuration = 1000;
    const maxDuration = 2000;
    const randomDuration =
      Math.floor(Math.random() * (maxDuration - minDuration + 1)) + minDuration;
    const response = await fetchAPI('/auth/reset-password-request', {
      method: 'POST',
      body: body,
    });
    // Delay for UX
    await new Promise((resolve) => setTimeout(resolve, randomDuration));
    if (!(response.statusCode == 200)) {
      setError(true);
    }
    setLoading(false);
  };
  return (
    <>
      {isOpen && (
        <div className='modal-backdrop show' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}></div>
      )}
      <div
        className={`modal ${isOpen ? 'show' : ''}`}
        tabIndex={-1}
        role='dialog'
        style={{ display: isOpen ? 'block' : 'none' }}
        data-testid='requestResetPasswordModal'
      >
        <div className='modal' tabIndex={-1} role='dialog' style={{ display: 'block' }}>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{t('forgotPassword')}</h5>
                <button
                  type='button'
                  className='btn btn-close'
                  onClick={closeModal}
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body' style={{ minHeight: loading ? '6em' : 'auto' }}>
                {loading ? (
                  <div>
                    <Loading insideModal={'requestPassword'} />
                  </div>
                ) : sent ? (
                  error ? (
                    <p>{t('errorResetRequest')}</p>
                  ) : (
                    <p>{t('resetSent')}</p>
                  )
                ) : (
                  <>
                    <p>{t('resetMessage')}</p>
                    <form onSubmit={handleSubmit(requestReset)}>
                      {/* Form for user to enter email or username to receive reset email */}
                      <div>
                        <label htmlFor='name'>{t('userId')}</label>
                        <input
                          type='text'
                          {...register('emailOrUsername', { required: t('userIdRequiredError') })}
                          className={`form-control ${errors.emailOrUsername ? 'is-invalid' : ''}`}
                        />
                        {errors.emailOrUsername && (
                          <div className='invalid-feedback'>{errors.emailOrUsername.message}</div>
                        )}
                      </div>
                    </form>
                  </>
                )}
              </div>
              <div className='modal-footer'>
                {!sent && (
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={handleSubmit(requestReset)}
                  >
                    {t('Send')}
                  </button>
                )}

                <button type='button' className='btn btn-secondary' onClick={closeModal}>
                  {t('close')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestResetPasswordModal;
