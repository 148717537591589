import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fetchAPI } from '../../utils/httpRequests';
import { useState } from 'react';
import { useAuth } from '../../utils/stores';
import Loading from '../../components/Loading';

interface newPassword {
  password: string;
  username: string;
  confirmPassword: string;
}

const Settings = () => {
  const { t } = useTranslation('global');
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
  } = useForm<newPassword>({
    defaultValues: {
      username: user?.username,
    },
  });

  const validatePassword = (value: string) => {
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(value);

    return value.length >= minLength && hasLowerCase && hasUpperCase && hasDigit && hasSpecialChar;
  };

  // Confirms that password and confirm password match
  const validateConfirmPassword = (value: string) => {
    const passwordValue = watch('password');
    return value === passwordValue || t('confirmPasswordError');
  };

  //function to reset password
  const resetPassword = async (body: newPassword): Promise<void> => {
    setLoading(true);
    setResetSent(true);
    const minDuration = 500;
    const maxDuration = 1000;
    const randomDuration =
      Math.floor(Math.random() * (maxDuration - minDuration + 1)) + minDuration;

    const response = await fetchAPI('/auth/set-new-password', {
      method: 'POST',
      body: body,
    });

    // random delay between 0.5-1 sec for UX
    await new Promise((resolve) => setTimeout(resolve, randomDuration));
    if (!(response.statusCode == 200)) {
      setError(true);
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    reset();
    setLoading(false);
    setResetSent(false);
    setError(false);
  };

  return (
    <div data-testid='settings'>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#staticBackdrop'
      >
        {t('resetPassword')}
      </button>

      {/* Reset password modal */}
      <div
        className='modal fade'
        id='staticBackdrop'
        tabIndex={-1}
        data-bs-backdrop='static'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                {t('resetPassword')}
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={handleModalClose}
              ></button>
            </div>
            <div className='modal-body'>
              {loading ? (
                <div>
                  <Loading insideModal={'requestPassword'} />
                </div>
              ) : resetSent ? (
                error ? (
                  <>
                    {/* Error with reset */}
                    <p>{t('resetFailed')}</p>
                  </>
                ) : (
                  <>
                    {/* Reset Succesful */}
                    <p>{t('resetSuccess')}</p>
                  </>
                )
              ) : (
                <>
                  {/* Fields to enter new password */}
                  <div className='row'>
                    <div className='col-sm-12'>
                      <label htmlFor='password'>{t('password')}</label>
                      <input
                        type='password'
                        {...register('password', {
                          required: t('passwordRequiredError'),
                          validate: (value: string) => validatePassword(value),
                        })}
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      />
                      {errors.password && (
                        <div className='invalid-feedback'>
                          {errors.password.type === 'required'
                            ? t('passwordRequiredError')
                            : t('passwordFormatError')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <label htmlFor='confirmPassword'>{t('confirmPassword')}</label>
                      <input
                        type='password'
                        {...register('confirmPassword', {
                          required: t('confirmPassword'),
                          validate: (value: string) => validateConfirmPassword(value),
                        })}
                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                      />
                      {errors.confirmPassword && (
                        <div className='invalid-feedback'>
                          {errors.confirmPassword.type === 'required'
                            ? t('confirmPasswordRequiredError')
                            : t('confirmPasswordMatchError')}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
                onClick={handleModalClose}
              >
                {t('close')}
              </button>
              {!resetSent && (
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={handleSubmit(resetPassword)}
                >
                  {t('save')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
