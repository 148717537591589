import DOMPurify from 'dompurify';

interface RenderUnsafeHTMLProps {
  html: string;
  className?: string;
}

const RenderUnsafeHTML = ({ html, className = '' }: RenderUnsafeHTMLProps) => {
  const sanitizedHtmlContent = DOMPurify.sanitize(html);

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
      data-testid='renderUnsageHTML'
    />
  );
};

export default RenderUnsafeHTML;
