import Modal from 'react-modal';
import RenderUnsafeHTML from '../RenderUnsafeHTML';
import { useTranslation } from 'react-i18next';

interface BasicModalProps {
  title: string;
  text?: string | null;
  html?: string | null;
  isOpen: boolean;
  closeModal: () => void;
}

const BasicModal = ({ title, text, html, isOpen, closeModal }: BasicModalProps) => {
  const { t } = useTranslation('global');

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel={title ?? 'Modal'}>
      <div>
        <div className='d-flex w-100 justify-content-between'>
          <h2>{title}</h2>
          <button onClick={closeModal} className='btn btn-primary'>
            {t('close')}
          </button>
        </div>
        {html && <RenderUnsafeHTML html={html} />}
        {text && <div>{text}</div>}
      </div>
    </Modal>
  );
};

export default BasicModal;
