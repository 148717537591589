import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../utils/stores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export const TeacherOnlyRoutes = () => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const { loading, signedIn, user } = useAuth(); // Contains the user and its state
  const [isTeacher, setIsTeacher] = useState<boolean | null>(null); // Contains the role of the user

  useEffect(() => {
    // Check the role of the user
    if (!loading) {
      if (signedIn && user?.teacher) {
        setIsTeacher(true);
      } else {
        setIsTeacher(false);
      }
    }
  }, [signedIn, navigate, loading]);

  // Set a loading the screen if the data hasn't been fetched yet
  // Block the user if he isn't a teacher
  return loading ? (
    <div
      className='w-100 min-vh-100 d-flex justify-content-center'
      style={{ marginTop: '10%' }}
      data-testid='teacherOnlyRoutes'
    >
      <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  ) : isTeacher ? (
    <Outlet />
  ) : (
    <div className='w-100 min-vh-100 d-flex justify-content-center' style={{ marginTop: '5%' }}>
      <div className='d-flex flex-column align-items-center gap-2'>
        <FontAwesomeIcon className='text-danger' size='3x' icon={faWarning} />
        <h1>{t('not_allowed_access_page')}</h1>
        <p>{t('please_make_sure_you_have_valid_permissions')}</p>
      </div>
    </div>
  );
};
